import network from "@/api/network";

export default class Promo {
  static async getPromoList(page, perPage, data) {
    return await network.get(`actions/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async modifyPromo(data) {
    return await network.post("actions", data, {});
  }

  static async exportPromoList(data, config) {
    return await network.get("actions/list/filter/export", data, config);
  }
}
