import prize from "./modules/prize";
import members from "./modules/members";
import usercard from "./modules/usercard";
import delivery from "./modules/delivery";
import points from "./modules/points";
import reports from "./modules/reports";
import user from "./modules/user";
import general from "./modules/general";
import deals from "./modules/deals";
import articles from "./modules/articles";
import mailout from "./modules/mailout";
import taxes from "./modules/taxes";
import promo from "./modules/promo";

class Api {
  constructor() {
    this.prize = prize;
    this.members = members;
    this.usercard = usercard;
    this.delivery = delivery;
    this.points = points;
    this.reports = reports;
    this.user = user;
    this.general = general;
    this.deals = deals;
    this.articles = articles;
    this.mailout = mailout;
    this.taxes = taxes;
    this.promo = promo;
  }
}

export default new Api();
